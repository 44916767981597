import * as React from 'react'
import { css } from '@emotion/react'
import { colors, gutter, theme } from '../utils/theme'
import { DummyContextRandomizer, DummyGoogleResult } from './DummyGoogleResult'
import { googleStyleTitle } from '../utils/capitalize'
import { Card, FormControl, Tab, Tabs, TextField, Typography } from '@mui/material'
import { Copy } from './Copy'
import { ChangeViewEvent, triggerGAEvent } from '../utils/googleAnalytics'

const cardStyle = css`
  display: flex;
  flex-direction: column;
`

const cardContentStyle = css`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const tabListStyle = css`
  .MuiTabs-indicator {
    top: 0;
    bottom: auto;
  }
`

const formControlStyle = css`
  display: flex;
  flex: 1;
`

const textareaStyle = css`
  display: flex;
  flex: 1;
  background-color: ${colors.grey[100]};

  .MuiInputBase-root {
    flex: 1;
    align-items: start;
    padding-bottom: ${gutter(6)};
  }

  .MuiInputBase-input {
    font-size: ${theme.typography.h6.fontSize};
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`

const bookTemplateStyle = css`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 190px;
  height: 100%;

  ${theme.breakpoints.up('md')} {
    min-height: 285px;
  }
`

const bookTitleStyle = css`
  margin: ${gutter(2)};
  white-space: pre-line;
  text-align: center;
  font-size: 2.5rem;
  font-weight: normal;
  line-height: 1.4em;
  border-radius: 1px;

  &:focus {
    outline: 3px solid var(--color-primary);
  }
`

const disabledTitleStyle = css`
  color: ${theme.palette.grey[500]};
`

const dummyContainerStyle = css`
  flex: 1;
  position: relative;
  overflow: hidden;
  margin-left: ${gutter(2)};
  margin-right: ${gutter(2)};
  min-height: 40px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.75) 50%,
      rgba(255, 255, 255, 1)
    );
  }
`

const dummyContentStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-family: ${theme.typography.subtitle1.fontFamily};

  p {
    margin: 0;
  }
`

const dummyContentBookStyle = css`
  text-indent: 1em;
  text-align: justify;
`

const googleTemplateStyle = css`
  ${bookTemplateStyle}

  ${theme.breakpoints.up('md')} {
    min-height: 375px;
  }
`

const googleTitleStyle = css`
  margin-top: ${gutter(2)};
  margin-left: ${gutter(2)};
  margin-right: ${gutter(2)};
`

const wikiTemplateStyle = css`
  ${bookTemplateStyle}
  margin-top: ${gutter(2.5)};
  margin-left: ${gutter(3)};
  margin-right: ${gutter(3)};
`

const dummyContainerWikiStyle = css`
  ${dummyContainerStyle}
  margin-left: 0;
  margin-right: 0;
`

const dummyContentWikiStyle = css`
  color: #202122;
  font-size: 14px;
  line-height: 1.6;
  font-family: sans-serif;

  p + p {
    margin-top: 0.5em;
  }
`

const wikipediaTitleStyle = css`
  border-bottom: 1px solid #a2a9b1;
  margin-bottom: 0.25em;
  line-height: 1.3;
  font-size: 1.8rem;
  font-family: 'Linux Libertine', 'Georgia', 'Times', serif;
`

const wikipediaInfoStyle = css`
  color: #202122;
  font-size: 12.88px;
  line-height: 1.6;
  margin-bottom: 16px;
`

const wikipediaNoteStyle = css`
  padding-left: 22.4px;
  margin-bottom: 11px;
  color: #202122;
  font-size: 14px;
  font-style: italic;
  line-height: 1.6;
`

const defaultCopyStyle = css`
  position: absolute;
  z-index: 1;
  right: ${gutter(0.5)};
  bottom: ${gutter(0.5)};
`

const bookCopyStyle = css`
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;

  &,
  &:hover {
    background-color: white;
  }
`

const TABS = {
  default: 'default',
  bookTitle: 'bookTitle',
  google: 'google',
  wikipedia: 'wikipedia',
}

export const TitlePresentation = ({ title, ...rest }) => {
  const defaultRef = React.useRef()
  const bookTitleRef = React.useRef()
  const googleTitleRef = React.useRef()
  const wikipediaTitleRef = React.useRef()
  const [selectedTab, setSelectedTab] = React.useState(TABS.default)

  const onTabChange = React.useCallback((e, newTab) => {
    triggerGAEvent(new ChangeViewEvent(newTab))
    setSelectedTab(newTab)
  }, [])

  const googleTitle = React.useMemo(() => googleStyleTitle(title), [title])

  const modifiedTitle = React.useMemo(() => {
    switch (selectedTab) {
      case TABS.default:
        return defaultRef
      case TABS.wikipedia:
        return wikipediaTitleRef
      case TABS.google:
        return googleTitleRef
      default:
        return bookTitleRef
    }
  }, [selectedTab])

  React.useEffect(() => {
    if (!defaultRef.current) {
      return
    }

    defaultRef.current.value = title
  }, [title])

  return (
    <Card css={cardStyle} {...rest}>
      <div css={cardContentStyle}>
        {selectedTab === TABS.default && (
          <FormControl fullWidth css={formControlStyle}>
            <TextField
              inputRef={defaultRef}
              css={textareaStyle}
              placeholder="The result will be here"
              area-label="Correctly formatted text"
              multiline
              defaultValue={title}
            />
            <Copy css={defaultCopyStyle} titleRef={modifiedTitle} />
          </FormControl>
        )}
        {selectedTab === TABS.bookTitle && (
          <div css={bookTemplateStyle}>
            {title ? (
              <Typography
                component="div"
                variant="subtitle1"
                ref={bookTitleRef}
                css={bookTitleStyle}
                contentEditable
                suppressContentEditableWarning
              >
                {title}
              </Typography>
            ) : (
              <Typography
                component="div"
                variant="subtitle1"
                css={[bookTitleStyle, disabledTitleStyle]}
              >
                Title placeholder
              </Typography>
            )}
            <div css={dummyContainerStyle}>
              <Copy css={bookCopyStyle} titleRef={modifiedTitle} />
              <div css={[dummyContentStyle, dummyContentBookStyle]}>
                <p>
                  This is just a dummy text. Orci a scelerisque purus semper eget duis at tellus.
                  Volutpat lacus laoreet non curabitur gravida. At ultrices mi tempus imperdiet
                  nulla malesuada pellentesque. Feugiat scelerisque varius morbi enim nunc faucibus
                  a pellentesque. Eget lorem dolor sed viverra ipsum.
                </p>
                <p>
                  Nunc congue nisi vitae suscipit tellus mauris a diam. Ultrices neque ornare aenean
                  euismod elementum nisi. Cras tincidunt lobortis feugiat vivamus at. Ut tristique
                  et egestas quis ipsum suspendisse. Sed vulputate odio ut enim blandit volutpat
                  maecenas. Amet nisl suscipit adipiscing bibendum est ultricies.
                </p>
              </div>
            </div>
          </div>
        )}
        {selectedTab === TABS.google && (
          <div css={googleTemplateStyle}>
            <DummyContextRandomizer>
              <DummyGoogleResult
                css={googleTitleStyle}
                title={
                  googleTitle ? (
                    <div ref={googleTitleRef} contentEditable suppressContentEditableWarning>
                      {googleTitle}
                    </div>
                  ) : (
                    <div css={disabledTitleStyle}>Title placeholder</div>
                  )
                }
              />
              <div css={dummyContainerStyle}>
                <Copy css={bookCopyStyle} titleRef={modifiedTitle} />
                <div css={dummyContentStyle}>
                  <DummyGoogleResult />
                  <DummyGoogleResult />
                </div>
              </div>
            </DummyContextRandomizer>
          </div>
        )}
        {selectedTab === TABS.wikipedia && (
          <div css={wikiTemplateStyle}>
            {title ? (
              <div
                ref={wikipediaTitleRef}
                css={wikipediaTitleStyle}
                contentEditable
                suppressContentEditableWarning
              >
                {title}
              </div>
            ) : (
              <div css={[wikipediaTitleStyle, disabledTitleStyle]}>Title placeholder</div>
            )}
            <div css={wikipediaInfoStyle}>From Wikipedia, the free encyclopedia</div>
            <div css={wikipediaNoteStyle}>
              This is an example not that wikipedia usually put under the title.
            </div>
            <div css={dummyContainerWikiStyle}>
              <Copy css={bookCopyStyle} titleRef={modifiedTitle} />
              <div css={[dummyContentStyle, dummyContentWikiStyle]}>
                <p>
                  This is just a dummy text. Orci a scelerisque purus semper eget duis at tellus.
                  Volutpat lacus laoreet non curabitur gravida. At ultrices mi tempus imperdiet
                  nulla malesuada pellentesque.
                </p>
                <p>
                  Feugiat scelerisque varius morbi enim nunc faucibus a pellentesque. Eget lorem
                  dolor sed viverra ipsum. Nunc congue nisi vitae suscipit tellus mauris a diam.
                  Ultrices neque ornare aenean euismod elementum nisi. Cras tincidunt lobortis
                  feugiat vivamus at.
                </p>
                <p>
                  Ut tristique et egestas quis ipsum suspendisse. Sed vulputate odio ut enim blandit
                  volutpat maecenas. Amet nisl suscipit adipiscing bibendum est ultricies.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <Tabs
        css={tabListStyle}
        value={selectedTab}
        onChange={onTabChange}
        variant="fullWidth"
        aria-label="Appearances"
      >
        <Tab label="Default" value={TABS.default} />
        <Tab label="Book" value={TABS.bookTitle} />
        <Tab label="Google" value={TABS.google} />
        <Tab label="Wikipedia" value={TABS.wikipedia} />
      </Tabs>
    </Card>
  )
}
